import * as React from "react"
import styled from "styled-components"
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Footer = (props) => {

  return (
    <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true" offset={-100}>
      <Note className={"cover" + props.type}>
        <div className={props.type}>
          If you'd like to learn more about me or my work, <a href="mailto:rustem.giniyatullin@gmail.com">hit me up</a>!
        </div>

        {props.type !== "sm" && <nav className="contacts">
          <a href="mailto:rustem.giniyatullin@gmail.com">Mail</a>
          <a href="https://www.linkedin.com/in/rustemginiyatullin/" target="_blank" rel="noreferrer">Linkedin</a>
          {/* <a href="https://github.com/rustemginiyatullin" target="_blank" rel="noreferrer">Github</a>
          <a href="https://Twitter.com/rustem_gin" target="_blank" rel="noreferrer">Twitter</a> */}
        </nav>}
      </Note>
    </AnimationOnScroll>
  )
}

const Note = styled.div`
  text-align: center;
  padding: var(--spacing-16) var(--spacing-4);
  flex-grow: 1;

  &.cover {
    background: #fff;
  }

  a {
    white-space: nowrap;
  }

  a::after {
    display: none;
  }

  .sm {
    font-size: 17px;
    padding: 0 20px;
  }

  .contacts {
    padding: var(--spacing-8) 0;
  }

  .contacts a {
    margin: 0 10px;
  }

  .dark & {
    background: var(--color-text);
  }
`
export default Footer
