exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-clubworx-js": () => import("./../../../src/pages/projects/clubworx.js" /* webpackChunkName: "component---src-pages-projects-clubworx-js" */),
  "component---src-pages-projects-farol-js": () => import("./../../../src/pages/projects/farol.js" /* webpackChunkName: "component---src-pages-projects-farol-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-magicbook-js": () => import("./../../../src/pages/projects/magicbook.js" /* webpackChunkName: "component---src-pages-projects-magicbook-js" */),
  "component---src-pages-projects-premium-parking-js": () => import("./../../../src/pages/projects/premium-parking.js" /* webpackChunkName: "component---src-pages-projects-premium-parking-js" */),
  "component---src-pages-projects-premium-parking-kiosks-js": () => import("./../../../src/pages/projects/premium-parking/kiosks.js" /* webpackChunkName: "component---src-pages-projects-premium-parking-kiosks-js" */),
  "component---src-pages-projects-premium-parking-mobile-js": () => import("./../../../src/pages/projects/premium-parking/mobile.js" /* webpackChunkName: "component---src-pages-projects-premium-parking-mobile-js" */),
  "component---src-pages-projects-premium-parking-od-js": () => import("./../../../src/pages/projects/premium-parking/od.js" /* webpackChunkName: "component---src-pages-projects-premium-parking-od-js" */),
  "component---src-pages-projects-premium-parking-pam-js": () => import("./../../../src/pages/projects/premium-parking/pam.js" /* webpackChunkName: "component---src-pages-projects-premium-parking-pam-js" */),
  "component---src-pages-projects-premium-parking-rebrand-js": () => import("./../../../src/pages/projects/premium-parking/rebrand.js" /* webpackChunkName: "component---src-pages-projects-premium-parking-rebrand-js" */),
  "component---src-pages-projects-premium-parking-textpay-js": () => import("./../../../src/pages/projects/premium-parking/textpay.js" /* webpackChunkName: "component---src-pages-projects-premium-parking-textpay-js" */),
  "component---src-pages-projects-premium-parking-textpay-old-js": () => import("./../../../src/pages/projects/premium-parking/textpay-old.js" /* webpackChunkName: "component---src-pages-projects-premium-parking-textpay-old-js" */),
  "component---src-pages-projects-twiage-js": () => import("./../../../src/pages/projects/twiage.js" /* webpackChunkName: "component---src-pages-projects-twiage-js" */),
  "component---src-pages-projects-upscribed-js": () => import("./../../../src/pages/projects/upscribed.js" /* webpackChunkName: "component---src-pages-projects-upscribed-js" */),
  "component---src-pages-projects-uula-js": () => import("./../../../src/pages/projects/uula.js" /* webpackChunkName: "component---src-pages-projects-uula-js" */),
  "component---src-pages-projects-wwgeg-js": () => import("./../../../src/pages/projects/wwgeg.js" /* webpackChunkName: "component---src-pages-projects-wwgeg-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

