// custom typefaces
import "typeface-montserrat"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

import React from 'react';
import {
	 getSessionPassword,
	 getQueryPassword,
	 isProtectedPage
} from '@mkitio/gatsby-theme-password-protect/src/utils/utils';

import PasswordProtect from './src/@mkitio/gatsby-theme-password-protect/components/PasswordProtect';

export const wrapPageElement = ({ props }, THEME_OPTIONS) => {
	const { password, pagePaths, partialMatching } = THEME_OPTIONS;
	const { location } = props;

	// password protection disabled
	if (!password) {
		return;
	}

	// page-level protection
	const isPageLevelProtectionOn = pagePaths && pagePaths.length > 0;
	if (isPageLevelProtectionOn) {
		// non-protected page
		if (!isProtectedPage(location, pagePaths, partialMatching)) {
			return;
		}
	}

	// correct password
	const passwordCandidate = getQueryPassword(location) || getSessionPassword();
	if (passwordCandidate === password) {
		return;
	}

	// check password
	return <PasswordProtect />;
};
