import React, { useState, useEffect } from 'react';
// import * as React from "react"
import styled from "styled-components"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { Link } from "gatsby"

let prevPos = 0;
const delay = 1;
const Header = (props) => {
  
  const [scrolled, setScrolled] = useState(false);

  const route = window.location.pathname.split('/')[1];

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 300;
      const scrolled =  prevPos > window.scrollY && isScrolled;
      let timer = setTimeout(() => setScrolled(scrolled), delay * 1000);
      // setScrolled(scrolled);

      prevPos = window.scrollY;

      return () => {
        clearTimeout(timer);
      };
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <HeaderNav data-scrolled={scrolled} className={props.location + '-nav ' + props.type + "-nav"}>
      <Link to="/" className="logo">Rustem</Link>
      <Nav>
      {props.nav === "Berlin" ?<Link to="/" className={(props.location === 'home' ? 'about active' : 'about')}>About</Link>: ''}
        <Link className={((route === 'work' || route === 'projects') ? 'active' : '')} to="/work">Work</Link>
        {/* <Link to="/#projects">Work</Link> */}
        {/* {props.nav === "Berlin" && <a href="https://drive.google.com/file/d/11zDfIZ396ecBa5fLmr_oHrNrrjujtEU6/view?usp=sharing" rel="noreferrer" target="_blank">CV</a>} */}

        <span className="theme-switch">
          <ThemeToggler>
            {({ theme, toggleTheme }) => (
              <label className={"theme-switcher switcher-" + theme}>
                <input
                  type="checkbox"
                  className="theme-checkbox"
                  onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                  checked={theme === 'dark'}
                />{' '}
                Dark mode
              </label>
            )}
          </ThemeToggler>
        </span>
      </Nav>
    </HeaderNav>
  )
}

const Nav = styled.nav`
  display: inline-block;
  padding: 0 2px 2px 10px;
  border-radius: 28px;
  font-size: 18px;
  line-height: 36px;
  vertical-align: middle;

  .no-auth-nav &,
  .home-nav & {
    background: none;
  }

  a[aria-current="page"],
  .active {
    background: rgba(29,29,31, .7);
    backdrop-filter: blur(10px);
    border: 0;
    pointer-events: none;
    padding: 0 15px;
    display: inline-block;
    border-radius: 28px;
    margin: 0;

    .dark & {
      background: rgba(219,219,231, .1);
      backdrop-filter: blur(10px);
    }

    .light & {
      color: var(--color-text);
      background: var(--color-bg);
    }
  }

  @media (min-width: 1800px) {
    margin-right: 0;
  }

  @media (min-width: 668px) {
    margin-right: 30px;
  }

  @media (max-width: 480px) {
    padding-left: 0;
  }

  @media (max-width: 400px) {
    a[aria-current="page"],
    .active {
      padding: 0 10px;
    }
  }
`

const HeaderNav = styled.div`
  padding: 10px 20px;
  text-align: right;

  .logo,
  .logo:hover {
    border: 0;
  }

  a {
    margin: 0 15px;
  }

  a:after {
    display: none;
  }

  @media (max-width: 990px) {
    .logo {
      margin-left: 0;
    }
  }

  @media (min-width: 1800px) {
    padding: 20px 0;
  }

  &[data-scrolled="true"] {
    z-index: 11;
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
    backdrop-filter: blur(10px);
    position: fixed;

    .logo {
      display: inline-block;
    }

    .dark & {
      background: rgba(29,29,31, .7);
    }

    .light & {
      background: rgba(255, 255, 255, .9);
    }
  }

  @media (max-width: 480px) {
    padding: 10px;

    .logo {
      margin-right: 0;
    }

    .about {
      display: none;

      &.active {
        display: none;
      }
    }

    a {
      margin: 0 10px;
    }
  }
`

export default Header;
