import React, { useState } from 'react';
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import styled from "styled-components"
import Footer from '../../../components/footer';
import Header from '../../../components/header';
import { AnimationOnScroll } from 'react-animation-on-scroll';

 const PasswordProtect = () => {
   const [password, setPassword] = useState('');
 
   const onSubmit = event => {
     event.preventDefault();
     setSessionPassword(password);
     window.location.reload(); // eslint-disable-line
   };
 
   return (
    <>
      <Header location={'inner'} type={'dark'}/>
      <Modal>
        <AnimationOnScroll animateIn="animate__pulse" animateOnce="true">
          <Form onSubmit={onSubmit}>
            <Icon xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 752 752">
              <path d="m538.58 410.81c0-21.879-17.711-39.59-39.543-39.543h-11.699l-0.046875-93.25c-0.046875-63.504-51.715-115.17-115.22-115.12-63.508 0.046875-115.13 51.715-115.13 115.22l0.046875 93.25-4.1172-0.003906c-21.832 0.046875-39.543 17.758-39.543 39.59l0.09375 138.62c0 21.832 17.711 39.543 39.543 39.543l246.17-0.13672c21.832 0 39.543-17.711 39.543-39.543zm-239.11-39.449-0.046875-93.25c-0.046875-40.113 32.582-72.742 72.648-72.789 40.113 0 72.742 32.629 72.789 72.695l0.042969 93.25z"/>
            </Icon>
            <label htmlFor="field">Enter password to view portfolio</label>
            <Field
                name="password"
                type="password"
                id="field"
                value={password}
                placeholder="Password"
                onChange={event => setPassword(event.target.value)}
              />
            <Btn type="submit">Continue</Btn>
          </Form>
        </AnimationOnScroll>
        <Footer type="sm" />
      </Modal>
    </>
   );
 };
 
 const Modal = styled.div`
  padding-top: 20vh;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;

  @media screen and (prefers-color-scheme: light) {
    background: var(--color-bg-darker);
  }

  @media (max-width: 667px) {
    padding-top: 80px;
  }

  .light & {
    background: var(--color-bg-darker);
  }

  .dark & {
    background: var(--color-text);
  }
`

const Icon = styled.svg`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  display: block;
  margin: 2rem auto;
  background: var(--color-text);
  box-shadow: 0 0 0 10px var(--color-text);

  path {
    fill: var(--color-accent);

    .light & {
      fill: var(--color-text-light);
    }

    .dark & {
      fill: var(--color-accent);
    }
  }

  @media screen and (prefers-color-scheme: light) {
    background: var(--color-bg-darker);
    box-shadow: 0 0 0 10px var(--color-bg-darker);
  
    path {
      fill: var(--color-text-light);
    }
  }

  .light & {
    background: var(--color-bg-darker);
    box-shadow: 0 0 0 10px var(--color-bg-darker);
  }

  .dark & {
    background: var(--color-text);
    box-shadow: 0 0 0 10px var(--color-text);
  }
`

const Form = styled.form`
  background: rgba(215,215,235, .1);
  max-width: 500px;
  margin: auto;
  padding: 1rem 1rem 3rem;
  border-radius: 18px;
  text-align: center;
  
  label {
    display: block;
    font-size: 1rem;
    margin-bottom: .5rem;
  }

  @media screen and (prefers-color-scheme: light) {
    background: var(--color-accent);
    box-shadow: 0 0 18px 0 rgba(88, 88, 88, .1);
  }

  .light & {
    background: var(--color-accent);
    box-shadow: 0 0 18px 0 rgba(88, 88, 88, .1);
  }

  .dark & {
    background: rgba(215,215,235, .1);
  }

  @media (max-width: 540px) {
    margin: 0 10px;
  }
`

const Btn = styled.button`
  background: var(--color-primary);
  color: var(--color-accent);
  border: 0;
  margin: 0 auto;
  height: 60px;
  display: block;
  font-size: var(--fontSize-3);
  padding: 0 var(--spacing-6);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: var(--color-primary-shift);
  }

  @media (max-width: 360px) {
    width: 100%;
    margin: 0;
  }
`

const Field = styled.input`
  height: 60px;
  font-size: var(--fontSize-3);
  border-radius: 8px;
  max-width: 100%;
  border: solid 2px var(--color-border-light);
  padding: 0 1rem;
  margin-bottom: 1rem;
  outline: none;

  &:focus {
    border-color: var(--color-primary);
  }

  @media (max-width: 360px) {
    width: 100%;
  }
`
 export default PasswordProtect;
 